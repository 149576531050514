import React, { useEffect, useCallback, useRef, useState } from "react";
import moment from "moment";
import { useGlobalContext } from "../context/GlobalContext";
import styles from "../styles/countdown.module.scss";

const calculateDuration = (eventTime) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    "seconds"
  );

function Countdown({ eventTime, interval }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  return (
    <div className={styles.timerText}>
      <div>
        {/* {duration.days() > 0 && (
          <>
            <span style={big}>{duration.days()}D</span>{" "}
          </>
        )} */}
        <span>{duration.hours()}H</span> <span>{duration.minutes()}M</span>{" "}
        <span>{duration.seconds()}S</span>
      </div>
      <div style={{ fontSize: "40", textTransform: "uppercase" }}>
        Refresh page once timer hits zero
      </div>
    </div>
  );
}

const CountDown = () => {
  const { saleStatusHooks, isOnWhitelist } = useGlobalContext();

  return (
    <div className={styles.wholePageDark}>
      <div className={styles.container} style={{ overflowY: "hidden" }}>
        {saleStatusHooks.presale === false &&
          saleStatusHooks.publicsale === false && <h2>Whitelist</h2>}
        {saleStatusHooks.presale === true &&
          saleStatusHooks.publicsale === false &&
          !isOnWhitelist && <h2>General</h2>}
        <p
          style={{ fontSize: 100, textAlign: "center", letterSpacing: "-1px" }}
        >
          <Countdown eventTime={1639731600} interval={1000} />
        </p>
      </div>
    </div>
  );
};

export default CountDown;
