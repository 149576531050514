import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import * as API from "../utils/utils";
import { useNavigate } from "react-router-dom";

import ProcessingModal from "../components/ProcessingModal";

import { injected, walletconnect } from "../connectors";
import { useGlobalContext } from "../context/GlobalContext";

import styles from "../styles/mint.module.scss";

const ConnectorNames = {
  Injected: "Metamask",
  Network: "Network",
  WalletConnect: "WalletConnect",
  WalletLink: "WalletLink",
  Ledger: "Ledger",
  Trezor: "Trezor",
  Lattice: "Lattice",
  Frame: "Frame",
  Authereum: "Authereum",
  Fortmatic: "Fortmatic",
  Magic: "Magic",
  Portis: "Portis",
  Torus: "Torus",
};

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network. Connect to ETH Mainnet.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

export default function () {
  return <Mint />;
}

function Mint() {
  const context = useWeb3React();
  const { connector, account, deactivate, active, error } = context;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amountToMint, setAmountToMint] = useState(1);
  const [mintError, setMintError] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
  const [purchaseSuccessful, setPurchaseSuccessful] = useState(false);

  const {
    setTransactionProcessing,
    isOnWhitelist,
    setIsOnWhitelist,
    salt,
    saleStatusHooks,
    setTx,
  } = useGlobalContext();

  useEffect(() => {
    if (error) {
      setIsModalOpen(false);
    }
  }, [error]);

  const getActiveConnector = () => {
    let active;
    Object.keys(connectorsByName).map((name) => {
      const currentConnector = connectorsByName[name];
      const connected = currentConnector === connector;
      if (connected) {
        active = name;
      }
    });
    return active;
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!active) {
      console.log(`WALLET NOT ACTIVATED ON MINT PAGE`);
      navigate("/");
    }
  }, [context]);

  useEffect(() => {
    getTotalSupply();
  }, []);

  const getTotalSupply = async () => {
    try {
      await API.getTotalSupply().then((total) => {
        setTotalSupply(total);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const doMint = async () => {
    if (
      saleStatusHooks.presale === true &&
      saleStatusHooks.publicsale === false
    ) {
      await doPresaleMint();
    } else if (
      saleStatusHooks.presale === false &&
      saleStatusHooks.publicsale === true
    ) {
      await doPublicSaleMint();
    }
  };

  const doPublicSaleMint = async () => {
    try {
      if (!isProcessingModalOpen) {
        setIsProcessingModalOpen(true);
      }
      if (mintError) {
        setMintError("");
      }
      if (purchaseSuccessful) {
        setPurchaseSuccessful(false);
      }
      let currentConnector = getActiveConnector();
      await API.signMessagePublic(
        account,
        currentConnector.toLowerCase(),
        context
      ).then(async (resp) => {
        if (resp.token && resp.salt) {
          await API.doPublicMint(amountToMint, resp).then(async (tx) => {
            console.log(`doPublicSaleMint TX`, tx);
            setTransactionProcessing(true);
            setTx(tx.hash);
            await tx.wait().then(() => {
              setPurchaseSuccessful(true);
              setTimeout(() => {
                getTotalSupply();
              }, 3000);
            });
          });
        }
      });
    } catch (e) {
      setTransactionProcessing(false);
      let errMsg;
      if (e.data) {
        if (e.data.message) {
          errMsg = e.data.message;
        } else {
          errMsg = e.message;
        }
      } else {
        errMsg = e.message;
      }
      if (errMsg.includes("execution reverted:")) {
        errMsg = errMsg.replace("execution reverted:", "");
      }
      setMintError(errMsg);
    }
  };

  const doPresaleMint = async () => {
    try {
      if (!isProcessingModalOpen) {
        setIsProcessingModalOpen(true);
      }
      if (mintError) {
        setMintError("");
      }
      if (purchaseSuccessful) {
        setPurchaseSuccessful(false);
      }
      await API.doPresaleMint(salt).then(async (tx) => {
        console.log(`doPresaleMint TX`, tx);
        setTransactionProcessing(true);
        setTx(tx.hash);
        await tx.wait().then(() => {
          setPurchaseSuccessful(true);
          setTimeout(() => {
            getTotalSupply();
          }, 3000);
        });
      });
    } catch (e) {
      setTransactionProcessing(false);
      let errMsg;
      if (e.data) {
        if (e.data.message) {
          errMsg = e.data.message;
        } else {
          errMsg = e.message;
        }
      } else {
        errMsg = e.message;
      }
      if (errMsg.includes("execution reverted:")) {
        errMsg = errMsg.replace("execution reverted:", "");
      }
      setMintError(errMsg);
    }
  };

  const phraseMap = {
    1: "That's the one",
    2: "It takes two to tango",
    3: "Three's a party",
    4: "Fourrrocious!",
    5: "High five!",
  };

  function handleInputChange(e) {
    let target = e.target;

    const min = target.min;
    const max = target.max;
    const val = target.value;

    target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
  }

  useEffect(() => {
    if (!isProcessingModalOpen) {
      setMintError("");
    }
  }, [isProcessingModalOpen]);

  return (
    <>
      {isProcessingModalOpen && (
        <ProcessingModal
          setIsProcessingModalOpen={setIsProcessingModalOpen}
          purchaseSuccessfulProp={purchaseSuccessful}
          errorMsg={mintError}
        />
      )}
      <div className={`${styles.container} text-center`}>
        <div className={`${styles.innerContainer} max-w-3xl mx-auto`}>
          {!!error && (
            <div style={{ width: "100%" }}>
              <h4
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                className="text-red-700"
              >
                {getErrorMessage(error)}
              </h4>
            </div>
          )}
          <div
            className=" sm:flex sm:justify-center aos-init aos-animate"
            data-aos="zoom-y-out"
            data-aos-delay="300"
            style={{ height: "100%" }}
          >
            {account && (
              <div className={styles.textContainer}>
                {!!totalSupply && !isOnWhitelist && (
                  <div className={styles.totalSupplyBox}>
                    <p>{`${parseInt(totalSupply)}/9669`}</p>
                    <p>Party Bears</p>
                    <p>Minted</p>
                  </div>
                )}
                {isOnWhitelist ? <h2>Whitelist</h2> : <h2>General</h2>}
                {isOnWhitelist ? (
                  <>
                    <p className={`${styles.header} text-xl`}>{amountToMint}</p>
                    <p>{parseFloat(0.09 * amountToMint).toFixed(2)}</p>
                    <p>ETH</p>
                    {mintError && (
                      <div
                        className={`${styles.errorText} mt-4 mb-3 text-red-700`}
                      >
                        {mintError}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <p className={`${styles.header} text-xl`}>{amountToMint}</p>
                    <p>{parseFloat(0.09 * amountToMint).toFixed(2)}</p>
                    <p>ETH</p>
                    {mintError && (
                      <div
                        className={`${styles.errorText} mt-4 mb-3 text-red-700`}
                      >
                        {mintError}
                      </div>
                    )}
                    <input
                      type="range"
                      min="1"
                      max="5"
                      value={amountToMint}
                      className={styles.slider}
                      id="myRange"
                      onChange={(e) => {
                        handleInputChange(e);
                        setAmountToMint(e.target.value);
                      }}
                    />
                    <div className={styles.dynamicMintTextBox}>
                      <p className={styles.dynamicMintText}>
                        {phraseMap[amountToMint]}
                      </p>
                    </div>
                  </>
                )}

                <div style={{ margin: "20px  0 10px 0" }}>
                  <button
                    className={styles.button}
                    size="large"
                    disabled={amountToMint <= 0}
                    onClick={() => {
                      doMint();
                    }}
                  >
                    {isOnWhitelist ? (
                      <>
                        <p className={styles.buttonText}>Mint Now</p>
                      </>
                    ) : (
                      "Mint Now"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
