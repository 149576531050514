import React from "react";

import styles from "../styles/footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src="/partybearlogo.svg"
        alt="Party Bear Logo"
      />
      <p>{"© Copyright Party Bears Ltd 2021, All Rights Reserved"}</p>
    </div>
  );
};

export default Footer;
