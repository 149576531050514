import React, { useState, useEffect } from "react";
import { injected, walletconnect } from "../connectors";
import styles from "../styles/processingModal.module.scss";
import { useNavigate, Navigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useGlobalContext } from "../context/GlobalContext";
import { saleStatus } from "../utils/utils";
import { Spinner } from "./Spinner.jsx";
import * as API from "../utils/utils";
import { ExternalLinkIcon } from "@heroicons/react/solid";

const ProcessingModal = ({
  setIsProcessingModalOpen,
  errorMsg,
  refreshTotalSupply,
  purchaseSuccessfulProp,
  purchaseFailedProp,
}) => {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const getSaleStatus = () => {
    console.log("Sale Status in Processing");
    try {
      API.saleStatus().then((resp) => {
        setSaleStatusHooks(resp);
      });
    } catch (e) {}
  };

  const {
    setTransactionProcessing,
    transactionProcessing,
    saleStatusHooks,
    setSaleStatusHooks,
    tx,
  } = useGlobalContext();

  const router = useNavigate();

  const [purchaseSuccessful, setPurchaseSuccessful] = useState(
    purchaseSuccessfulProp || false
  );
  const [purchaseFailed, setPurchaseFailed] = useState(
    purchaseFailedProp || false
  );

  useEffect(() => {
    if (purchaseFailedProp !== purchaseFailed) {
      setPurchaseFailed(purchaseFailedProp);
    }
  }, [purchaseFailedProp]);

  useEffect(() => {
    if (purchaseSuccessfulProp !== purchaseSuccessful) {
      setPurchaseSuccessful(purchaseSuccessfulProp);
    }
  }, [purchaseSuccessfulProp]);

  useEffect(() => {
    if (errorMsg && !purchaseFailed) {
      setPurchaseFailed(true);
    }
  }, [errorMsg]);

  useEffect(() => {
    getSaleStatus();
  }, []);

  useEffect(() => {
    setTransactionProcessing(false);
  }, [purchaseSuccessful]);

  return (
    <div className={styles.wholePageDark}>
      <div className={styles.container}>
        {purchaseSuccessful || purchaseFailed ? (
          <div
            onClick={() => setIsProcessingModalOpen(false)}
            className={styles.closeButtonContainer}
          >
            <img className={styles.closeButton} src="/X.svg" alt="close icon" />
          </div>
        ) : (
          ""
        )}

        <div className={styles.sectionTop}>
          {purchaseSuccessful ? (
            <div style={{ position: "relative", zIndex: 3 }}>
              <h3>
                {saleStatusHooks.publicsale
                  ? "Success! NFTs Secured"
                  : "Success! NFT Secured"}
              </h3>
              <p onClick={() => setPurchaseFailed(true)}>
                {saleStatusHooks.publicsale
                  ? "Your PARTY BEAR(s) have been minted."
                  : "Your PARTY BEAR has been minted."}
              </p>
            </div>
          ) : (
            <div style={{ position: "relative", zIndex: 3 }}>
              {purchaseFailed ? (
                <>
                  <h3>{"UH OH, THAT DIDN’T WORK"}</h3>
                  <p>
                    {saleStatusHooks === "presale"
                      ? "Unfortunately your transaction failed."
                      : "Transaction failed, your wallet hasn’t been charged."}
                  </p>
                  <p className={styles.errorMsg}>{errorMsg}</p>
                </>
              ) : (
                <React.Fragment>
                  <h3>
                    {!transactionProcessing ? (
                      "Confirm Transaction"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          flexGrow: 1,
                        }}
                      >
                        <div>Processing Mint </div>
                        <div style={{ marginLeft: 15 }}>
                          <Spinner color="#ebebeb" height={32} width={32} />
                        </div>
                      </div>
                    )}
                  </h3>

                  {!transactionProcessing ? (
                    <p>Interact with your wallet to continue</p>
                  ) : (
                    <p
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      Check your transaction on:
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.anchorEthers}
                        style={{ display: "flex", marginLeft: 5 }}
                        href={`https://etherscan.io/tx/${tx}`}
                      >
                        Etherscan{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                          <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                        </svg>
                      </a>
                    </p>
                  )}
                  {saleStatusHooks.publicsale === true && (
                    <p className={styles.errorMsg}>
                      {
                        "Wait for the transaction to complete before trying to mint more."
                      }
                    </p>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>

        <div className={styles.imgContainer}>
          <img
            className={styles.bossBear}
            src="/bossBear.png"
            alt="Boss Party Bear"
          />
        </div>
        <div className={styles.sectionBottom}>
          {errorMsg === " The wallet address has already minted in presale." ? (
            <p style={{ textTransform: "uppercase" }}>
              {"come back soon for main sale"}
            </p>
          ) : (
            ""
          )}
          {purchaseSuccessful ? (
            <React.Fragment>
              {saleStatusHooks.presale === false ? (
                <button
                  disabled={false}
                  onClick={() => {
                    setIsProcessingModalOpen(false);
                  }}
                  className={styles.connectButton}
                >
                  Mint More
                </button>
              ) : (
                <p style={{ textTransform: "uppercase" }}>
                  {"come back soon for main sale"}
                </p>
              )}
            </React.Fragment>
          ) : (
            <>
              {purchaseFailed &&
                errorMsg !==
                  " The wallet address has already minted in presale." && (
                  <button
                    disabled={false}
                    onClick={() => {
                      setIsProcessingModalOpen(false);
                    }}
                    className={styles.connectButton}
                  >
                    Try Again
                  </button>
                )}
            </>
          )}
          {saleStatusHooks.publicsale === true &&
            !purchaseFailed &&
            !purchaseSuccessful && (
              <div className={styles.warningMessage}>
                <h6>{"Important:"}</h6>
                <p>
                  If you queue up a second transaction before your first is
                  approved, the second transaction will fail and gas will be
                  lost. This is an essential anti-bot/whale measure. Please wait
                  until your first transaction is approved to avoid
                  disappointment.
                </p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ProcessingModal;
