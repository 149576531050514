import { ethers } from "ethers";
import abi from "../abis/abi.json";
import fetch from "node-fetch";

const PRICE_PER_NFT = ethers.utils.bigNumberify("90000000000000000");
const CONTRACT_ADDRESS = "0x35471f47c3C0BC5FC75025b97A19ECDDe00F78f8";
const SALT_API_URL = "https://salt-api.partybear.xyz";

const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const extractBody = async (res) => {
  if (!res.ok) {
    throw new Error(await res.text());
  } else {
    return res.json();
  }
};

let provider, signer, contractProvider, contractSigner;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);

  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
} else {
  // INFURA FALLBACK RPC HERE
  let fallbackProvider = ethers.getDefaultProvider("homestead", {});
  contractProvider = new ethers.Contract(
    CONTRACT_ADDRESS,
    abi,
    fallbackProvider
  );
}

export const updateProviderAndSigner = (library) => {
  if (library) {
    provider = new ethers.providers.Web3Provider(library.provider);
  } else {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
};

export const getTotalSupply = async () => {
  if (contractProvider) {
    let supply = await contractProvider.totalSupply();
    return supply.toLocaleString("en-US");
  } else {
    return "?";
  }
};

export const doPresaleMint = async (salt) => {
  let tx = await contractSigner.presaleMint(salt.salt, salt.token, {
    value: PRICE_PER_NFT,
  });

  return tx;
};

export const doPublicMint = async (amount, salt) => {
  let amountBN = new ethers.utils.BigNumber(amount);
  let totalAmount = amountBN.mul(PRICE_PER_NFT);
  let tx = await contractSigner.mint(salt.salt, salt.token, amount, {
    value: totalAmount,
  });

  return tx;
};

const sleep = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
export const signMessage = async (walletToAuthenticate, provider, context) => {
  let message = `This signature is to see if you are on the whitelist. It is free of charge.`;

  let signature;

  if (provider === "walletlink") {
    await sleep();
  }

  if (provider === "walletconnect" || provider === "walletlink") {
    signature = await context.library.provider.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate.toLowerCase(),
    ]);
  } else {
    signature = await signer.signMessage(message);
  }

  let { salt, token } = await retrieveSalt(message, signature);

  return { salt, token };
};

export const retrieveSalt = async (message, signature) => {
  return await fetch(`${SALT_API_URL}/api/salt`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ message, signature }),
  }).then((response) => extractBody(response));
};

export const signMessagePublic = async (
  walletToAuthenticate,
  provider,
  context
) => {
  let message = `This signature is to prove you are minting from this official page. It is free of charge.`;

  let signature;

  if (provider === "walletlink") {
    await sleep();
  }

  if (provider === "walletconnect" || provider === "walletlink") {
    signature = await context.library.provider.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate.toLowerCase(),
    ]);
  } else {
    signature = await signer.signMessage(message);
  }

  let { salt, token } = await retrievePublicSalt(message, signature);

  return { salt, token };
};

export const retrievePublicSalt = async (message, signature) => {
  return await fetch(`${SALT_API_URL}/api/publicsalt`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ message, signature }),
  }).then((response) => extractBody(response));
};

export const saleStatus = async () => {
  return await fetch(`${SALT_API_URL}/salestarted`, {
    method: "GET",
  }).then((response) => extractBody(response));
};
