import { Windmill } from "@windmill/react-ui";
import Mint from "./pages/Mint";
import ConnectWallet from "./pages/ConnectWallet";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useGlobalContext } from "./context/GlobalContext";

import styles from "./styles/home.module.scss";

function App() {
  const { isOnWhitelist } = useGlobalContext();
  const { active } = useWeb3React();
  return (
    <div className={styles.container}>
      <Windmill>
        <BrowserRouter>
          {/* <Header /> */}
          <div className={styles.headerContainer}>
            <img
              className={styles.logo}
              src="/partybearlogo.svg"
              alt="Party Bear Logo"
            />
            <h1 className={styles.headerText}>Mint Party Bears</h1>
          </div>

          <div className={`${styles.main}`}>
            <div
              className={
                !isOnWhitelist && active
                  ? styles.whitelistImgContainer
                  : styles.imgContainer
              }
            >
              <img
                className={styles.bossBear}
                src="/bossBear.png"
                alt="Boss Party Bear"
              />
            </div>

            <Routes>
              <Route path="/" element={<ConnectWallet />} />
              <Route path="/mint" element={<Mint />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </Windmill>
    </div>
  );
}

export default App;
